import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Delete,
  FlashOn,
  Schedule,
  Send,
  Image as ImageIcon,
  Add,
} from "@material-ui/icons";
import DateTimeDialog from "./DateTimeDialog";
import { useDispatch, useSelector } from "react-redux";
import { changeRoute } from "../navSlice";
import axios from "../axios";
import { useHistory } from "react-router-dom";
const imgRegEx = /\[\[(\d+\.(jpg|png|gif|jpeg|webp))\]\]/gm;
export default function ScheduleTweet() {
  const dispatch = useDispatch();
  const customImg = useRef();
  const history = useHistory();
  useEffect(() => {
    dispatch(changeRoute("جدولة التغريدات"));
  }, [dispatch]);
  const { token, nickname, avatar } = useSelector((state) => state.authReducer);
  const classes = useStyles();
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [body, setBody] = useState("");
  const [tweets, setTweets] = useState([]);
  const [imageLoading, setImageLoading] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTweets(body.split(/\n\n\n/));
  }, [body]);
  function handleTextChange(e) {
    const text = e.target.value;
    setBody(text);
  }
  function addNewTweet() {
    if (body.length === 0) {
      setBody("تغريدة جديدة...");
    } else {
      setBody((b) => b + "\n\n\nتغريدة جديدة...");
    }
  }
  async function tweetNow(scheduled = null) {
    if (scheduled) scheduled = scheduled.toISOString();
    try {
      setLoading(true);
      const res = await axios.post("api/tweetNow", {
        token,
        tweets: tweets.filter((t) => t.trim().length > 0),
        isScheduled: scheduled !== null,
        scheduled,
      });
      if (res.status === 200) {
        setBody("");
        setTweets([]);
        setLoading(false);
        if (scheduled) {
          history.push("queue");
        } else if (res.data && /^\d+$/.test(res.data)) {
          window.location.href = `https://twitter.com/${nickname}`;
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }
  const pickImage = () => {
    if (customImg) {
      customImg.current.click();
    }
  };
  const imageSelected = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return;
    reader.fileName = file.name;
    reader.onloadend = function () {
      if (!reader.fileName.match(/.(jpg|jpeg|png|gif|webp)$/i)) {
        alert("Only jpg, gif, png or webp file format is allowed!");
        return;
      }
      const img = reader.result;
      setImageLoading(true);
      axios
        .post("api/image", { token, img })
        .then((res) => res.data)
        .then((data) => {
          setImageLoading(false);
          setBody((b) => b + `\n[[${data.name}]]\n`);
        })
        .catch((e) => {
          alert(e.message || "Upload failed!");
          setImageLoading(false);
        });
      customImg.current.value = "";
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  function deleteImage(img) {
    setBody((b) => b.replace(img, ""));
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7}>
          <Typography variant="h6" color="primary" align="center" dir="rtl">
            أكتب سلسلة التغريدات
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            style={{
              color: "#555",
              fontSize: 17,
            }}
            dir="rtl"
          >
            <span style={{ marginLeft: 5 }}>
              <FlashOn style={{ fontSize: 16 }} />
            </span>
            اضغط مسافة مرتين (انتر)لتبدأ تغريدة جديدةضمن الثريد
          </Typography>
          <TextField
            dir="rtl"
            multiline
            rows={28}
            style={{ minHeight: 480 }}
            value={body}
            onChange={handleTextChange}
            color="primary"
            variant="outlined"
            fullWidth
          />
          <div style={{ marginTop: 10, textAlign: "right" }}>
            <Button
              color="primary"
              onClick={pickImage}
              size="small"
              variant="outlined"
              disabled={
                loading ||
                imageLoading ||
                tweets.length === 0 ||
                tweets[0].trim().length === 0
              }
            >
              {imageLoading ? (
                <CircularProgress size={25} color="primary" />
              ) : (
                <ImageIcon />
              )}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Typography variant="h6" color="primary" align="center" dir="rtl">
            مثال
          </Typography>
          <Typography variant="subtitle2">&nbsp;</Typography>
          <div className={classes.tweetBody}>
            {tweets.map((twt, idx) => {
              const tweet = twt.replace(
                /\[\[\d+\.(jpg|jpeg|png|gif|webp)\]\]/gim,
                ""
              );
              let tBody = tweet;
              let images = (twt.match(imgRegEx) || []).map((i) =>
                i.replace(/\[\[|\]\]/g, "")
              );
              if (images.length > 4) {
                images = images.slice(0, 4);
              }
              if (tweet.length > 280) {
                tBody =
                  tweet.substring(0, 280) +
                  `<span>${tweet.substring(281, tweet.length)}</span>`;
              }
              tBody = tBody.replace(/\n/gm, `<br/>`);
              return (
                <div className={classes.tweet} key={idx}>
                  <div className={classes.avatarContainer}>
                    <img className={classes.avatar} src={avatar} alt="avatar" />
                    <div
                      className={classes.line}
                      style={{
                        display: tweets.length === idx + 1 ? "none" : "block",
                      }}
                    />
                  </div>
                  <div className={classes.tweetContent}>
                    <p>
                      <span>@{nickname}</span>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tBody.replace(
                          /(?:(https?:\/\/[^\s]+|[#|@]\b(?![0-9]+\b)[a-zA-Z0-9_]+\b))/gm,
                          `<span style="background-color:#fff;font-weight:600;color:#1da1f2">$1</span>`
                        ),
                      }}
                      className={classes.tweetText}
                      dir="rtl"
                    />
                    <div className={classes.attachmentsContainer}>
                      <div className={classes.attachments}>
                        {images.map((img, id) => (
                          <div key={id}>
                            <img
                              src={`https://adawat.tech/twitter/images/${nickname}-${img}`}
                              alt="attachment"
                            />
                            <div onClick={() => deleteImage(`[[${img}]]`)}>
                              <Delete style={{ color: "#f11", fontSize: 32 }} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <input
              ref={customImg}
              type="file"
              accept="image/jpeg, image/png, image/webp, .svg"
              id="customImg"
              style={{ display: "none" }}
              onChange={imageSelected}
            />
            <div className={classes.buttonGroup}>
              <Button
                style={{ margin: 5 }}
                variant="outlined"
                onClick={addNewTweet}
              >
                <Add />
              </Button>
              <Button
                style={{ flex: 1, margin: 5 }}
                color="secondary"
                variant="contained"
                onClick={() => setScheduleDialog(true)}
                disabled={
                  loading ||
                  tweets.length === 0 ||
                  tweets[0].trim().length === 0
                }
                dir="rtl"
              >
                <Schedule style={{ marginLeft: 5 }} />
                جدولها
              </Button>
              <Button
                style={{ flex: 1, margin: 5 }}
                color="primary"
                variant="contained"
                onClick={() => tweetNow()}
                disabled={
                  loading ||
                  tweets.length === 0 ||
                  tweets[0].trim().length === 0
                }
              >
                غردها
                <Send style={{ marginLeft: 5 }} />
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <DateTimeDialog
        onClose={() => setScheduleDialog(false)}
        open={scheduleDialog}
        dialogClass={classes.hideUnderline}
        handleSchedule={tweetNow}
        loading={loading}
      />
    </>
  );
}

const useStyles = makeStyles({
  tweetBody: {
    position: "relative",
    maxWidth: 360,
    height: "calc(100vh - 200px)",
    overflowY: "scroll",
    margin: "0 auto",
    "& p": {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  tweet: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 50,
    marginRight: 10,
  },
  line: {
    width: 3,
    flexGrow: 1,
    background: "#cfcfd5",
    margin: "5px auto",
  },
  avatar: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: 48,
    maxWidth: "100%",
    display: "block",
    verticalAlign: "middle",
    margin: "0 auto",
  },
  tweetText: {
    wordWrap: "break-word",
    fontSize: 18,
    "& span": {
      backgroundColor: "rgba(255, 51, 78, 0.3)",
    },
    marginRight: 5,
  },
  tweetContent: {
    flexGrow: 1,
    flexDirection: "column",
    marginBottom: 20,
    "&> p span": {
      color: "#888",
    },
  },
  attachmentsContainer: {
    position: "relative",
    minWidth: "98%",
    width: "calc(100% - 20px)",
    borderRadius: 16,
    marginTop: 15,
    overflow: "hidden",
    boxShadow: "rgb(150, 150, 150) 1px 1px 4px 0px",
  },
  attachments: {
    display: "grid",
    gridGap: 3,
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    borderRadius: 16,
    "& div": {
      width: "100%",
      height: "100%",
      position: "relative",
      "&:hover div": {
        display: "flex",
      },
    },
    "& div div": {
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff9",
      cursor: "pointer",
    },
    "& div img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      boxShadow: "rgb(150, 150, 150) 1px 1px 4px 0px",
    },
  },
  buttonGroup: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    background: "white",
  },
  hideUnderline: {
    "& .MuiGrid-container": { minHeight: 120 },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#ffffff",
    },
  },
});
