import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  GridList,
  GridListTile,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Button,
  Radio,
} from "@material-ui/core";
import { CloudDownload, Search, Twitter } from "@material-ui/icons";
import axios from "../axios";

import ColorButton from "./ColorButton";
import HidingButton from "./HidingButton";
import Sliders from "./Sliders";
import { useDispatch } from "react-redux";
import { changeRoute } from "../navSlice";

import domToImage from "dom-to-image";
import download from "downloadjs";
import { useRef } from "react";

export default function TweetToInsta() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeRoute("تغريدة الى انستقرام"));
  }, [dispatch]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tweetURL, setTweetURL] = useState("");
  const [tweetID, setTweetID] = useState("");
  const [tweetBody, setTweetBody] = useState(null);
  const [primaryTextColor, setPrimaryTextColor] = useState("#000000");
  const [linkColor, setLinkColor] = useState("#1B95E0");
  const [secondaryTextColor, setSecondaryTextColor] = useState("#5B7083");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [background, setBackground] = useState("#FFFFFF");
  const [dimensions, setDimensions] = useState({ width: 1080, height: 1080 });
  const [hidden, setHidden] = useState({
    avatar: false,
    username: false,
    verifiedIcon: false,
    time: false,
    source: false,
    likes: false,
    retweets: false,
    replies: false,
    signature: true,
  });
  const [zoom, setZoom] = React.useState(0.5);
  const [textWidth, setTextWidth] = React.useState(1);

  function hideSection(which) {
    setHidden((prev) => ({
      ...prev,
      [which]: !prev[which],
    }));
  }
  function handleURL(e) {
    setTweetURL(e.target.value);
  }
  function parseTweetId() {
    const regex = /\/\b(\d+)\b/;
    let m;
    if ((m = regex.exec(tweetURL)) !== null) {
      setTweetID(m[1]);
    }
    if (tweetID.length > 0) {
      setIsLoading(true);
      axios
        .get("twt/" + tweetID)
        .then((res) => {
          if (res.status === 200) {
            setTweetBody(res.data);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }
  const bgImg = useRef();
  const pickImage = () => {
    if (!!backgroundImage) {
      setBackgroundImage("");
      return;
    }
    if (bgImg) {
      bgImg.current.click();
    }
  };
  const imageSelected = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return;
    reader.onloadend = function () {
      setBackgroundImage(reader.result);
      bgImg.current.value = "";
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  function downloadTweet() {
    const elm = document.getElementById("dLoad");
    elm.style.margin = 0;
    const w = (dimensions.width * 4) / elm.clientWidth;
    const h = (dimensions.height * 4) / elm.clientHeight;
    domToImage
      .toPng(elm, {
        width: dimensions.width * 4,
        height: dimensions.height * 4,
        style: { transformOrigin: "center", transform: `scale(${w},${h})` },
        quality: 1,
      })
      .then(function (blob) {
        elm.style.margin = "0 auto";
        elm.style.marginTop = "15px";

        const img = new window.Image();
        img.src = blob;
        img.width = dimensions.width;
        img.height = dimensions.height;
        img.onload = function () {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
          const cw = dimensions.width;
          const ch = dimensions.height;
          canvas.width = cw;
          canvas.height = ch;
          ctx.drawImage(img, 0, 0, cw, ch);
          const data = canvas.toDataURL();
          download(data, "tweet.png", "image/png");
        };
      });
  }

  const mediaCount = (tweetBody?.includes?.media || []).length;
  let rowCols = { rows: 1, cols: 2, maxHeight: 250 };
  if (mediaCount === 2) {
    rowCols = { rows: 2, cols: 1, maxHeight: 250 };
  } else if (mediaCount === 3) {
    rowCols = { rows: 1, cols: 1, maxHeight: 125 };
  } else if (mediaCount >= 4) {
    rowCols = { rows: 2, cols: 1, maxHeight: 125 };
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <Paper className={classes.tweet}>
          <Button
            className={classes.downloader}
            color="secondary"
            variant="contained"
            onClick={downloadTweet}
          >
            <CloudDownload />
          </Button>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel htmlFor="tURL">رابط التغريدة</InputLabel>
            <OutlinedInput
              id="tURL"
              value={tweetURL}
              onChange={handleURL}
              type="text"
              // autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={parseTweetId}
                    style={{ backgroundColor: "transparent" }}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={80}
              onBlur={parseTweetId}
              onBlurCapture={parseTweetId}
            />
          </FormControl>
          {isLoading && <LinearProgress style={{ marginTop: 10 }} />}
          <div
            id="dLoad"
            className={
              dimensions.height === 1920 ? classes.story : classes.gram
            }
            style={{
              backgroundColor: background,
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              position: "sticky",
              top: 0,
            }}
          >
            {!!tweetBody && !tweetBody.error ? (
              <>
                <div className={classes.main}>
                  <div
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: `scale(${zoom})`,
                      width: textWidth * 100 + "%",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        {!hidden.avatar && (
                          <div className={classes.flexNone}>
                            <img
                              className={classes.avatar}
                              src={
                                tweetBody.includes.users[0].profile_image_url
                              }
                              alt=""
                            />
                          </div>
                        )}
                        <div className={classes.px2}>
                          <span
                            className={classes.name}
                            style={{
                              color: primaryTextColor,
                            }}
                          >
                            {tweetBody.includes.users[0].name + " "}
                          </span>
                          <br />
                          <span
                            style={{
                              color: secondaryTextColor,
                            }}
                          >
                            {!hidden.username ? (
                              `@${tweetBody.includes.users[0].username}`
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className={classes.body}
                        style={{
                          color: primaryTextColor,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: window.twemoji.parse(
                            (tweetBody.data?.text || "").replace(
                              /(?:(https?:\/\/[^\s]+|[#|@]\b(?![0-9]+\b)[a-zA-Z0-9_]+\b))/gm,
                              `<button class=${classes.link} style="color: ${linkColor}">$1</button>`
                            )
                          ),
                        }}
                      ></div>
                      {mediaCount > 0 && (
                        <div className={classes.attachments}>
                          <GridList cellHight={125} spacing={1}>
                            {tweetBody.includes.media
                              .slice(0, 4)
                              .map((img, idx) => (
                                <GridListTile
                                  key={idx}
                                  style={{ maxHeight: rowCols.maxHeight }}
                                  cols={
                                    mediaCount === 3 && idx === 0
                                      ? 2
                                      : rowCols.cols
                                  }
                                  rows={
                                    mediaCount === 3 && idx === 0
                                      ? 2
                                      : rowCols.rows
                                  }
                                >
                                  <img src={img.url} alt={img.media_key} />
                                </GridListTile>
                              ))}
                          </GridList>
                        </div>
                      )}
                      <p
                        className={classes.footer}
                        style={{
                          color: secondaryTextColor,
                        }}
                      >
                        {!hidden.time && (
                          <span>
                            {formatTime(new Date(tweetBody.data.created_at))}
                          </span>
                        )}
                        {!hidden.time && !hidden.source && (
                          <span>&nbsp;&middot;&nbsp;</span>
                        )}
                        {!hidden.source && <span>{tweetBody.data.source}</span>}
                      </p>
                      <div
                        className={classes.flex}
                        style={{
                          marginTop: ".5rem",
                          marginBottom: ".5rem",
                        }}
                      >
                        {!hidden.replies && (
                          <div
                            style={{ display: "flex" }}
                            className={classes.flexOne}
                          >
                            <svg
                              className={classes.svg}
                              fill={secondaryTextColor}
                              viewBox="0 0 24 24"
                            >
                              <g>
                                <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
                              </g>
                            </svg>
                            <div
                              className={classes.count}
                              style={{ color: secondaryTextColor }}
                            >
                              {tweetBody?.data?.public_metrics?.reply_count ||
                                0}
                            </div>
                          </div>
                        )}
                        {!hidden.retweets && (
                          <div
                            style={{ display: "flex" }}
                            className={classes.flexOne}
                          >
                            <svg
                              className={classes.svg}
                              viewBox="0 0 24 24"
                              fill={secondaryTextColor}
                            >
                              <g>
                                <path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"></path>
                              </g>
                            </svg>
                            <div
                              className={classes.count}
                              style={{ color: secondaryTextColor }}
                            >
                              {tweetBody?.data?.public_metrics?.retweet_count ||
                                0}
                            </div>
                          </div>
                        )}
                        {!hidden.likes && (
                          <div
                            style={{ display: "flex" }}
                            className={classes.flexOne}
                          >
                            <svg
                              className={classes.svg}
                              viewBox="0 0 24 24"
                              fill={secondaryTextColor}
                            >
                              <g>
                                <path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"></path>
                              </g>
                            </svg>
                            <div
                              className={classes.count}
                              style={{ color: secondaryTextColor }}
                            >
                              {tweetBody?.data?.public_metrics?.like_count || 0}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Twitter style={{ fontSize: 120, color: "#47597e" }} />
                <p style={{ fontSize: 22, marginTop: 10, color: "#47597e" }}>
                  الصق الرابط
                </p>
              </>
            )}
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        {/* <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <TextField variant="outlined" label="Font Size" fullWidth />
          </Grid>
          <Grid item xs={8}>
            <TextField variant="outlined" label="Font Family" fullWidth />
          </Grid>
        </Grid> */}
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs={6}>
            <ColorButton
              color={background}
              onChange={(c) => setBackground(c.hex)}
              label="الخلفية"
            />
          </Grid>
          <Grid item xs={6}>
            <ColorButton
              color={primaryTextColor}
              onChange={(c) => setPrimaryTextColor(c.hex)}
              label="الخط الاساسي"
            />
          </Grid>
          <Grid item xs={6}>
            <ColorButton
              color={secondaryTextColor}
              onChange={(c) => setSecondaryTextColor(c.hex)}
              label="الخط الثانوي"
            />
          </Grid>
          <Grid item xs={6}>
            <ColorButton
              color={linkColor}
              onChange={(c) => setLinkColor(c.hex)}
              label="الروابط"
            />
          </Grid>
          <Grid item xs={12}>
            <input
              ref={bgImg}
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={imageSelected}
            />
            <Button
              onClick={pickImage}
              variant="outlined"
              style={{ color: !backgroundImage ? "#47597e" : "#f66" }}
              fullWidth
            >
              {!!backgroundImage ? "حذف صورة الخلفية" : "الصورة الخلفية"}
            </Button>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <strong>&nbsp;&nbsp;&nbsp;:البعد</strong>
            <Radio
              checked={dimensions.height === 1080}
              onClick={() => setDimensions({ width: 1080, height: 1080 })}
            />{" "}
            1080&times;1080
            <Radio
              checked={dimensions.height === 1920}
              onClick={() => setDimensions({ width: 1080, height: 1920 })}
            />{" "}
            1080&times;1920
          </div>
        </Grid>
        <Grid container spacing={1}>
          {Object.entries(hidden).map(([key, value]) => (
            <Grid item xs={4} key={key}>
              <HidingButton
                value={value}
                name={key}
                onChange={() => hideSection(key)}
              />
            </Grid>
          ))}
        </Grid>
        <div style={{ padding: "25px 5px" }}>
          <Sliders
            zoom={zoom}
            onZoomChange={setZoom}
            textWidth={textWidth}
            onWidthChange={setTextWidth}
          />
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tweet: {
    marginTop: 5,
    minWidth: 685,
    minHeight: 685,
    padding: theme.spacing(2),
    border: "1px solid #e2e8f0",
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.down("md")]: {
      minWidth: 0,
      minHeight: 0,
    },
    "@media (min-width: 680px)": {
      position: "relative",
    },
  },
  downloader: {
    position: "absolute",
    right: 10,
    bottom: 10,
    width: 60,
    height: 60,
    borderRadius: 100,
    zIndex: 1000,
    "@media (max-width: 680px)": {
      position: "fixed",
    },
  },
  gram: {
    position: "relative",
    margin: "0 auto",
    marginTop: 15,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: 500,
    height: 500,
    "@media (max-width: 960px)": {
      width: 685,
      height: 685,
    },
    "@media (max-width: 796px)": {
      width: 500,
      height: 500,
    },
    "@media (max-width: 680px)": {
      width: 360,
      height: 360,
      padding: 5,
    },
    "@media (max-width: 420px)": {
      width: 330,
      height: 330,
      padding: 5,
    },
    "@media (max-width: 410px)": {
      width: 300,
      height: 300,
      padding: 5,
    },
    "@media (max-width: 375px)": {
      width: 280,
      height: 280,
      padding: 5,
    },
  },
  story: {
    position: "relative",
    margin: "0 auto",
    marginTop: 15,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: 500,
    height: 500 * 1.7777,
    "@media (max-width: 960px)": {
      width: 685,
      height: 685 * 1.7777,
    },
    "@media (max-width: 796px)": {
      width: 500,
      height: 500 * 1.7777,
    },
    "@media (max-width: 680px)": {
      width: 360,
      height: 360 * 1.7777,
      padding: 5,
    },
    "@media (max-width: 420px)": {
      width: 330,
      height: 330 * 1.7777,
      padding: 5,
    },
    "@media (max-width: 410px)": {
      width: 300,
      height: 300 * 1.7777,
      padding: 5,
    },
    "@media (max-width: 375px)": {
      width: 280,
      height: 280 * 1.7777,
      padding: 5,
    },
  },
  main: {
    display: "flex",
    flex: "0 1 auto",
  },
  body: {
    fontSize: 23,
    lineHeight: "30.1875px",
    fontWeight: 400,
    whiteSpace: "pre-wrap",
    marginTop: 20,
    "& img.emoji": {
      verticalAlign: "-20%",
      width: "1.2em",
      height: "1.2em",
    },
  },
  footer: {
    fontSize: 15,
    lineHeight: "19.6875px",
    paddingTop: 10,
    paddingBottom: 10,
  },
  name: {
    fontWeight: 700,
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start",
  },
  px2: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  flexOne: {
    flex: "1 1 0%",
  },
  mt2: {
    marginTop: "0.5rem",
  },
  count: {
    fontWeight: 700,
    marginLeft: "0.75rem",
  },
  svg: {
    width: "1.25rem",
    "& svg": {
      verticalAlign: "middle",
    },
  },
  avatar: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: 9999,
    maxWidth: "100%",
    display: "block",
    verticalAlign: "middle",
    marginRight: 5,
  },
  flexNone: {
    flex: "none",
  },
  link: {
    border: "none",
    background: "inherit",
    fontSize: "100%",
  },
  attachments: {
    marginTop: 15,
    maxHeight: 250,
    overflow: "hidden",
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  mediaContainer: {
    display: "grid",
    gap: 2,
    width: "100%",
    height: 250,
  },
  mediaPreview: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
  watermark: {
    width: "100%",
    textAlign: "right",
    paddingRight: "2.5rem",
    paddingBottom: "2.5rem",
    "& span": {
      fontWeight: 700,
    },
  },
}));
function formatTime(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const day = date.getDay();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  hours = (hours + 24 - 2) % 24;
  let meridian = "AM";
  if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    meridian = "PM";
  }
  return `${("0" + hours).slice(-2)}:${("0" + minutes).slice(
    -2
  )} ${meridian} · ${month} ${day}, ${year}`;
}
