import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CheckCircleOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { logout } from "../authSlice";
import { changeRoute } from "../navSlice";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeRoute("Logged Out"));
    dispatch(logout());
  }, [dispatch]);

  return (
    <Grid container style={{ minHeight: 600 }}>
      <Grid item xs={false} sm={1} md={2}></Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 600,
        }}
      >
        <CheckCircleOutlined
          style={{
            color: "mediumseagreen",
            fontSize: 52,
          }}
        />
        <Typography
          variant="h6"
          style={{
            marginTop: 10,
            color: "mediumseagreen",
            textAlign: "center",
          }}
        >
          Logged Out Successfully!{" "}
        </Typography>
      </Grid>
      <Grid item xs={false} sm={1} md={2}></Grid>
    </Grid>
  );
}
