import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import {
  Twitter,
  Menu,
  ExitToApp,
  Instagram,
  Schedule,
  Queue,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { Collapse } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./authSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authReducer, navReducer: nav } = useSelector((state) => state);
  const { token, nickname, avatar } = authReducer;
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [expandNested, toggleExpandNested] = React.useState(false);

  const toggleDrawer =
    (open, goto = "") =>
    () => {
      setState(open);
      if (goto) {
        history.push(goto);
      }
    };

  return (
    <>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar dir="rtl">
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={toggleDrawer(true)}
          >
            <Menu style={{ color: "white" }} />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {nav.title}
          </Typography>
          {!!token ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar alt={nickname} src={avatar} className={classes.avatar} />
              <Typography
                variant="subtitle2"
                style={{ margin: "0 10px" }}
                dir="rtl"
              >
                مرحبا {nickname}
              </Typography>
              <a
                href="/logout"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Button className={classes.menuButton} color="inherit">
                  <ExitToApp style={{ transform: "scale(-1)" }} />
                </Button>
              </a>
            </div>
          ) : (
            <a
              rel="noreferrer"
              href="https://adawat.tech/twitter/token"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Button className={classes.menuButton} color="inherit">
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: 10 }}
                  dir="rtl"
                >
                  سجّل دخولك باستخدام تويتر
                </Typography>
                <Twitter />
              </Button>
            </a>
          )}
        </Toolbar>
      </AppBar>
      <Drawer open={state} onClose={toggleDrawer(false)}>
        <div className={classes.list} role="presentation">
          <List>
            <ListItem button onClick={toggleDrawer(false, "/")}>
              <ListItemIcon>
                <Instagram />
              </ListItemIcon>
              <ListItemText primary={"تغريدة الى انستقرام"} />
            </ListItem>

            <ListItem button onClick={() => toggleExpandNested((p) => !p)}>
              <ListItemIcon>
                <Twitter />
              </ListItemIcon>
              <ListItemText primary={"جدولة التغريدات"} />
              {expandNested ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expandNested} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  disabled={!token}
                  className={classes.nested}
                  onClick={toggleDrawer(false, "/schedule")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon>
                    <Schedule />
                  </ListItemIcon>
                  <ListItemText primary="تغريدة جديدة" />
                </ListItem>
                <ListItem
                  button
                  disabled={!token}
                  className={classes.nested}
                  onClick={toggleDrawer(false, "/queue")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon>
                    <Queue />
                  </ListItemIcon>
                  <ListItemText primary="التغريدات المجدولة" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
          <List>
            {!!token ? (
              <ListItem button onClick={() => dispatch(logout())}>
                <ListItemIcon>
                  <ExitToApp style={{ transform: "scale(-1)" }} />
                </ListItemIcon>
                <ListItemText primary={"تسجيل خروج"} />
              </ListItem>
            ) : (
              <a
                href="/logout"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ExitToApp color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Login"} />
                </ListItem>
              </a>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
}
