import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeRoute } from "../navSlice";
import axios from "../axios";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TweetQueue() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeRoute("آخر التغريدات المجدولة"));
  }, [dispatch]);
  const classes = useStyles();
  const { token } = useSelector((state) => state.authReducer);

  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    async function fetchQueue() {
      try {
        const res = await axios.get("api/queue?token=" + token);
        if (res.status === 200) {
          setTweets(res.data);
        }
      } catch (e) {
        //
      }
    }
    fetchQueue();
  }, [token]);

  async function deleteQueue(id) {
    await axios.post("api/queue/delete", { id, token });
    setTweets((prev) => prev.filter((p) => p.id !== id));
  }

  return (
    <Grid container>
      <Grid item xs={false} sm={1} md={2}></Grid>
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant="h4" style={{ textAlign: "center" }} gutterBottom>
          آخر التغريدات المجدولة
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#ID</TableCell>
                <TableCell>غردها</TableCell>
                <TableCell align="right">الجدولة فعّال</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tweets
                .filter((t) => t.in_reply_to_status_id === null)
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.tweet}
                      {tweets
                        .filter((t) => t.in_reply_to_status_id === row.id)
                        .map((t, idx) => (
                          <p key={idx}>
                            <strong>{"—".repeat(idx + 1)}&gt;</strong>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {t.tweet}
                          </p>
                        ))}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(row.schedule).toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      <Button rounded onClick={() => deleteQueue(row.id)}>
                        <Delete color="error" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {tweets.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} component="th" scope="row">
                    لا يوجد تغريدات مجدولة
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={false} sm={1} md={2}></Grid>
    </Grid>
  );
}
