import { Button, makeStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles({
  bigButton: {
    padding: "0 10px",
    margin: "5px 0",
    width: "100%",
    height: 100,
    fontSize: 12,
    border: "1px solid #ddd",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#47597e",
    },
    "& span": {
      color: "#293b5f",
      fontWeight: "bold",
      marginTop: 15,
    },
  },
});

const labels = {
  avatar: "الصورة الشخصية",
  username: "اسم المعرّف",
  verifiedIcon: "التوثيق",
  time: "الوقت",
  source: "المصدر",
  likes: "الاعجابات",
  retweets: "اعادة التغريد",
  replies: "الرد",
  signature: "توقيع",
};

export default function HidingButton({ value, name, onChange }) {
  const classes = useStyles();
  const [show, toggleShow] = useState(false);

  useEffect(() => {
    toggleShow(value);
  }, [value]);

  const handleClick = () => {
    onChange(value);
  };
  return (
    <Button className={classes.bigButton} onClick={handleClick}>
      {show ? <Visibility /> : <VisibilityOff />}
      <span>{labels[name]}</span>
    </Button>
  );
}
