import "date-fns";
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Timer } from "@material-ui/icons";

export default function DateTimeDialog({
  onClose,
  open,
  dialogClass = "",
  handleSchedule,
  loading,
}) {
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [openDP, toggleOpenDP] = useState(false);
  const [openTP, toggleOpenTP] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDateTime(date);
  };

  return (
    <Dialog onClose={onClose} open={open} className={dialogClass}>
      <DialogTitle id="simple-dialog-title">
        جدول التغريدة/سلسلة التغريدات
      </DialogTitle>
      <DialogContent>
        <DialogContentText dir="rtl">
          أختر الوقت والتاريخ الذي تريده لجدولة التغريدة/سلسلة التغريدات&mdash;
        </DialogContentText>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              open={openDP}
              onOpen={() => toggleOpenDP(true)}
              onClose={() => toggleOpenDP(false)}
              disablePast
              margin="normal"
              label="التاريخ"
              format="MM/dd/yyyy"
              value={selectedDateTime}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
              TextFieldComponent={(props) => (
                <TextField {...props} onClick={() => toggleOpenDP(true)} />
              )}
            />
            <KeyboardTimePicker
              open={openTP}
              onOpen={() => toggleOpenTP(true)}
              onClose={() => toggleOpenTP(false)}
              margin="normal"
              label="الوقت"
              value={selectedDateTime}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              keyboardIcon={<Timer />}
              InputProps={{ readOnly: true }}
              TextFieldComponent={(props) => (
                <TextField {...props} onClick={() => toggleOpenTP(true)} />
              )}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>
          الغاء
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => handleSchedule(selectedDateTime)}
          disabled={loading}
        >
          جدولها
        </Button>
      </DialogActions>
    </Dialog>
  );
}
