import { createSlice } from "@reduxjs/toolkit";

function getAuth() {
  const token = window.localStorage.getItem("token") || "";
  const nickname = window.localStorage.getItem("nickname") || "";
  const avatar = window.localStorage.getItem("avatar") || "";
  return { token, nickname, avatar };
}
function setAuth({ token, nickname, avatar }) {
  window.localStorage.setItem("token", token || "");
  window.localStorage.setItem("nickname", nickname || "");
  window.localStorage.setItem("avatar", avatar || "");
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    ...getAuth(),
  },
  reducers: {
    loadUser: (state) => {
      const local = getAuth();
      state.token = local.token;
      state.nickname = local.nickname;
      state.avatar = local.avatar;
    },
    logout: (state) => {
      setAuth({ token: null, nickname: null, avatar: null });
      state.token = "";
      state.nickname = "";
      state.avatar = "";
    },
    login: (state, { payload }) => {
      setAuth(payload);
      state.token = payload.token;
      state.nickname = payload.nickname;
      state.avatar = payload.avatar;
    },
  },
});

export const { loadUser, logout, login } = authSlice.actions;

export default authSlice.reducer;
