import { Container } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loadUser } from "./authSlice";
import Authorizing from "./components/Authorizing";
import Logout from "./components/Logout";
import ScheduleTweet from "./components/ScheduleTweet";
import TweetQueue from "./components/TweetQueue";
import TweetToInsta from "./components/TweetToInsta";
import Navbar from "./Navbar";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  return (
    <Router>
      <Navbar />
      <Container>
        <Switch>
          <Route exact path="/">
            <TweetToInsta />
          </Route>
          <Route exact path="/schedule">
            <ScheduleTweet />
          </Route>
          <Route exact path="/queue">
            <TweetQueue />
          </Route>
          <Route exact path="/auth">
            <Authorizing />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
