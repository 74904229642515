import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "96%",
  },
});

export default function Sliders({
  zoom,
  textWidth,
  onZoomChange,
  onWidthChange,
}) {
  const classes = useStyles();

  const handleZoomChange = (_, newValue) => {
    onZoomChange(newValue);
  };

  const handleWidthChange = (_, newValue) => {
    onWidthChange(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography gutterBottom dir="rtl">
        توسيع <strong>{zoom}</strong>
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <ZoomIn />
        </Grid>
        <Grid item xs>
          <Slider
            min={0.5}
            max={2}
            step={0.01}
            value={zoom}
            onChange={handleZoomChange}
          />
        </Grid>
        <Grid item>
          <ZoomOut />
        </Grid>
      </Grid>
      <br />
      <Typography gutterBottom dir="rtl">
        العرض <strong>{(textWidth * 100).toFixed(2)}%</strong>
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Add />
        </Grid>
        <Grid item xs>
          <Slider
            min={0.3}
            max={1}
            step={0.0005}
            value={textWidth}
            onChange={handleWidthChange}
          />
        </Grid>
        <Grid item>
          <Remove />
        </Grid>
      </Grid>
    </div>
  );
}
