import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "../axios";
import { login } from "../authSlice";
import { changeRoute } from "../navSlice";

export default function Authorizing() {
  const [token, setToken] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search !== "") {
      const params = new URLSearchParams(location.search);
      setToken(params.get("token"));
    }
  }, [location]);

  const [authorizing, setAuthorizing] = useState(true);
  useEffect(() => {
    dispatch(changeRoute("Logging In"));
    async function fetchUser() {
      if (token === "") {
        setAuthorizing(false);
        return;
      }
      try {
        setAuthorizing(true);
        const res = await axios.get(`api/login?token=${token}`);
        if (res.status === 200) {
          dispatch(login(res.data));
          window.location.replace("/");
        } else {
          setAuthorizing(false);
        }
      } catch (e) {
        setAuthorizing(false);
      }
    }
    fetchUser();
  }, [dispatch, token]);

  return (
    <Grid container style={{ minHeight: 600 }}>
      <Grid item xs={false} sm={1} md={2}></Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 600,
        }}
      >
        {authorizing ? (
          <>
            <CircularProgress size={100} color="primary" />
            <Typography variant="h4" color="primary" style={{ marginTop: 25 }}>
              Authorizing...
            </Typography>
          </>
        ) : (
          <>
            <ErrorOutline color="error" fontSize={"large"} />
            <Typography
              variant="h6"
              color="error"
              style={{ marginTop: 10, textAlign: "center" }}
            >
              Failed!
              <br />
              <small>Try again.</small>
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={false} sm={1} md={2}></Grid>
    </Grid>
  );
}
