import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { ChromePicker } from "react-color";

const useStyles = makeStyles({
  bigButton: {
    padding: "0 10px",
    margin: "5px 0",
    width: "100%",
    height: 120,
    border: "1px solid #ddd",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#666",
    },
    "& span": {
      color: "#47597e",
      fontWeight: "bold",
      marginTop: 10,
    },
  },
  colorCircle: {
    height: 40,
    width: 40,
    borderRadius: 9999,
    border: "1px solid #ccc",
  },
  colorCode: {
    fontSize: 12,
    color: "#888",
    margin: 0,
  },
  popover: {
    position: "absolute",
    zIndex: 2,
  },
  cover: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

export default function ColorButton({ label, color, onChange }) {
  const classes = useStyles();
  const [show, toggleShow] = useState(false);
  const handleClick = () => {
    toggleShow(true);
  };

  const handleClose = () => {
    toggleShow(false);
  };
  return (
    <>
      <Button className={classes.bigButton} onClick={handleClick}>
        <div
          style={{ backgroundColor: color }}
          className={classes.colorCircle}
        ></div>
        <p className={classes.colorCode}>{color}</p>
        <span>{label}</span>
      </Button>
      {show && (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <ChromePicker color={color} onChange={onChange} disableAlpha />
        </div>
      )}
    </>
  );
}
